<template>
	<div class="coming-soon">
		<div class="center retina-logo">
			<img src="@public/img/vigneron-online/logo.gif" alt="Vigneron-Online Logo">
		</div>

		<div class="center container clearfix">

			<div class="col topmargin-lg bottommargin-lg">

				<div class="heading-block">
					<h1><span>{{ $t('coming-soon.title') }}</span></h1>
					<h2>{{ $t('coming-soon.teaser.title') }}</h2>
					<span>{{ $t('coming-soon.teaser.sub-title') }}</span>
				</div>

				<p>{{ $t('coming-soon.teaser.text') }}</p>

				<a href="javascript:void(0)" @click="showContactModal" class="button button-border button-rounded button-large noleftmargin topmargin-sm">{{ $t('coming-soon.teaser.button') }}</a>

			</div>

		</div>

		<div class="center copyright"><small>{{ $t("footer.copyright", [year]) }}</small></div>
	</div>
</template>

<style>

.coming-soon {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  flex-direction: column;
}

.coming-soon:first-child {
	margin-top:auto;
}

.coming-soon:last-child {
	margin-bottom: auto;
}

.center.retina-logo img {
  height:90px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.copyright {
  margin-top:10px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { routerTypes, languagesTypes } from '@fwk-client/store/types';
import { types as layoutTypes } from '../../store/layout';

@Component({
  components: {}
})
export default class ComingSoon extends mixins(GenericPage) {

	year = (new Date()).getFullYear();

	showContactModal() {
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }

}
</script>